<template>
  <div>
    <a-breadcrumb>
      <a-breadcrumb-item
        ><a href="/orders2/Orders">Orders</a></a-breadcrumb-item
      >
      <a-breadcrumb-item>Order Status</a-breadcrumb-item>
    </a-breadcrumb>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Order Status"
      @back="() => $router.go(-1)"
    />
    <div class="box_content">
      <div class="wrap_content">
        <a-form layout="inline" :form="form" v-if="Status == 0">
          <a-form-item label="">
            <a-input
              v-decorator="[
                'str1',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Logistics company!',
                    },
                  ],
                },
              ]"
              placeholder="Logistics company"
            >
            </a-input>
          </a-form-item>
          <a-form-item label="">
            <a-input
              v-decorator="[
                'str2',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Courier number!',
                    },
                  ],
                },
              ]"
              placeholder="Courier number"
            >
            </a-input>
          </a-form-item>

          <a-form-item label="">
            <a-button
              key="submit"
              type="primary"
              :loading="loading"
              @click="handleOk"
            >
              submit
            </a-button>
          </a-form-item>
        </a-form>

        <a-descriptions title="Shipping information" :column="1">
          <a-descriptions-item label="Domestic logistics">
            中通快递75479955983169
          </a-descriptions-item>
          <a-descriptions-item label="Overseas logistics">
            17track YT2116921266048909
          </a-descriptions-item>
        </a-descriptions>

        <a-table
          :columns="columns"
          :data-source="data"
          :rowKey="(record, index) => index"
          size="middle"
        >
          <span slot="Products" slot-scope="text, data" class="td_black">
            <img
              v-bind:src="data.img"
              style="width: 50px; float: left; margin-right: 5px"
            />
            <div style="float: left">
              <p style="color: rgba(0, 0, 0, 0.85)">{{ data.Products }}</p>
              <p>Varlation:{{ data.Varlation }}</p>
              <p>SKU:{{ data.SKU }}</p>
            </div>
          </span>
          <span slot="action" slot-scope="text, data">
            <button
              class="btn"
              @click="showModal(data.Order)"
              v-if="data.Status == 1"
            >
              Set up head Logistics
            </button>
            <button
              class="btn"
              @click="showModal(data.Order)"
              v-if="data.Status == 2"
            >
              View details
            </button>
          </span>
        </a-table>

        <table style="float: right;margin-right: 50px;font-weight: 700;">
          <tr>
            <td>See Income Details</td>
            <td></td>
          </tr>
          <tr>
            <td style="padding-right:20px;">Merchandise Subtotal</td>
            <td style="text-align: right;">{{ IncomeDetails.str1 }}</td>
          </tr>
          <tr>
            <td>Shipping Subtotal</td>
            <td style="text-align: right;">{{ IncomeDetails.str2 }}</td>
          </tr>
          <tr>
            <td>Platform fee</td>
            <td style="text-align: right;">{{ IncomeDetails.str3 }}</td>
          </tr>
          <tr>
            <td>Datahunt fees</td>
            <td style="text-align: right;">{{ IncomeDetails.str4 }}</td>
          </tr>
          <tr>
            <td>Order Income</td>
            <td style="text-align: right;color: red;font-size: 25px;">{{ IncomeDetails.str5 }}</td>
          </tr>
        </table>
        <!-- <a-descriptions title="See Income Details" :column="1">
          <a-descriptions-item label="Merchandise Subtotal">
            {{ IncomeDetails.str1 }}
          </a-descriptions-item>
          <a-descriptions-item label="Shipping Subtotal">
            {{ IncomeDetails.str2 }}
          </a-descriptions-item>
          <a-descriptions-item label="Platform fee">
            {{ IncomeDetails.str3 }}
          </a-descriptions-item>
          <a-descriptions-item label="Datahunt fees">
            {{ IncomeDetails.str4 }}
          </a-descriptions-item>
          <a-descriptions-item label="Order Income">
            {{ IncomeDetails.str5 }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="See Income Details" style="display: none">
          <a-descriptions-item label="Merchandise Subtotal">
            {{ IncomeDetails.str1 }}
          </a-descriptions-item>
          <a-descriptions-item label="Shipping Subtotal">
            {{ IncomeDetails.str2 }}
          </a-descriptions-item>
          <a-descriptions-item label="Platform fee">
            {{ IncomeDetails.str3 }}
          </a-descriptions-item>
          <a-descriptions-item label="Datahunt fees">
            {{ IncomeDetails.str4 }}
          </a-descriptions-item>
          <a-descriptions-item label="Product cost">
            {{ IncomeDetails.str5 }}
          </a-descriptions-item>
          <a-descriptions-item label="Gross profit">
            {{ IncomeDetails.str6 }}
          </a-descriptions-item>
          <a-descriptions-item label="Share ratio">
            {{ IncomeDetails.str7 }}
          </a-descriptions-item>
          <a-descriptions-item label="Settlement amount">
            {{ IncomeDetails.str8 }}
          </a-descriptions-item>
        </a-descriptions> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOrderDetail,
  setOrderLogistics,
  getIncomeDetails,
} from "@/network/order2Reuqest";
const columns = [
  {
    title: "Product(s)",
    dataIndex: "Products",
    key: "Products",
    scopedSlots: { customRender: "Products" },
  },
  {
    title: "Unit Price",
    dataIndex: "UnitPrice",
    key: "UnitPrice",
  },
  {
    title: "Quantity",
    dataIndex: "Quantity",
    key: "Quantity",
  },
  {
    title: "Subtotal",
    dataIndex: "Subtotal",
    key: "Subtotal",
  },
];
export default {
  name: "NodeprojectMedia",
  components: {},
  directives: {},
  data() {
    return {
      IncomeDetails: {
        str1: 0,
        str2: 0,
        str3: 0,
        str4: 0,
        str5: 0,
        str6: 0,
        str7: 0,
        str8: 0,
      },
      Status: 1,
      Order: "",
      total_rows: 0,
      data: [],
      columns,
      loadingMore: false,
      showLoadingMore: true,
      loading: false,
      visible: false,
      loading: false,
      form: this.$form.createForm(this, { name: "user_from" }),
    };
  },
  created() {
    if (this.$route.query.Status) {
      this.Status = this.$route.query.Status;
    }
    if (this.$route.query.Order) {
      this.Order = this.$route.query.Order;
    }
    this.getList(1);
    getIncomeDetails(this.$route.query.Order).then((res) => {
      if (res.header.code == 200) {
        console.log(res.body.detail);
        this.IncomeDetails = res.body.detail;
      }
    });
  },
  mounted() {},
  methods: {
    getList(page) {
      getOrderDetail(page, this.Order).then((res) => {
        if (res.header.code == 200) {
          this.total_rows = res.body.total_rows;
          this.data = res.body.detail;
        }
      });
    },
    handleOk(e) {
      let that = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.info("success");
        console.log(values);
        if (!err) {
          that.loading = true;
          let obj = values;
          obj.id = that.Order;
          setOrderLogistics(obj).then((res) => {
            if (res.header.code == 200) {
              setTimeout(() => {
                that.visible = false;
                that.loading = false;
                that.Status = 2;
              }, 1500);
            }
          });
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
};
</script>

<style  scoped>
</style>